




import { BaseComponent, Component } from '@/components/BaseComponent';
import Spinner from '@/components/Spinner.vue';
import { Pages } from '@/types';

@Component({
  components: {
    Spinner,
  },
})
export default class ExtAuth1Callback extends BaseComponent {
  async created() {
    const params = new URLSearchParams(window.location.search);

    const error = params.get('error');
    const extAuth2State = params.get('state');
    const storedState = localStorage.getItem('id-auth-extauth2');

    if (error === 'access_denied' && storedState) {
      const data: {
        state: string;
        query: Record<string, string>;
      } = JSON.parse(storedState);

      if (extAuth2State === data.state) {
        this.$router.replace({
          name: Pages.Authorize,
          query: data.query,
        });
        return;
      }
    }

    const result = await this.pds.extAuth2Callback(params);
    const clientId = this.session.getClientId();
    if (clientId) {
      this.authorization.setClientId(clientId);
    }
    this.router.changePage(result);
  }
}
